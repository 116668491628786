<script lang="ts">
    import logo from "$lib/assets/logo_name_white.svg"
</script>

<!-- pc は bg-primary-main にする? -->
<footer class="flex flex-col items-center bg-primary-main">
    <a class="my-6" href="/"><img src={logo} alt="ワタシモ" id="logo" /></a>
    <p><a href="https://watashimo.studio.site/">会社概要</a></p>
    <p><a href="/privacy-policy">プライバシーポリシー</a></p>
    <p><a href="/terms-of-service">利用規約</a></p>
    <p><a href="https://forms.gle/UQkDQGQr2aH6FboD7">お問い合わせ</a></p>
    <p class="text-xs my-4">© 2022 ワタシモ All rights reserved.</p>
</footer>

<style>
img#logo {
    height: 22px;
}

footer p {
    @apply text-white;
}

a {
    @apply text-white;
    @apply text-xs;
}
</style>
