<script lang="ts">
	import Header from '$lib/Header.svelte';
	import Footer from '$lib/Footer.svelte';
	import '../app.css';

	import { onMount } from "svelte";
	import { PUBLIC_CLARITY_PROJECT_ID, PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID, PUBLIC_FACEBOOK_PIXEL_ID } from "$env/static/public";
	import { goto } from "$app/navigation";
  import { page } from "$app/stores";
	import auth from "$lib/auth";
  import { isAuthenticated } from "$lib/auth/store";

  import type { PageData } from './$types';
  // import type { PageData, Errors } from './$types';

  export let data: PageData;
  // export let errors: Errors;
  let { og_url, is_index } = data;

	onMount(async () => {
		const auth0Client = await auth.createClient();
		try {
			// ログイン後の callback は +page.svelte 側でハンドリングされる
			await auth0Client.getTokenSilently();
			$isAuthenticated = true;
		} catch (error) {
			if (error.error !== 'missing_refresh_token' && error.error !== 'login_required') {
				throw error;
			}
		}
	});

	$: {
		if (typeof gtag !== "undefined") {
      gtag("config", PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID, {
        page_title: document.title,
        page_path: $page.url.pathname,
      });
    }
	}
</script>

<svelte:head>
	{@html `
		<script>
			(function(c,l,a,r,i,t,y){
				c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
				t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
				y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
			})(window, document, "clarity", "script", "${PUBLIC_CLARITY_PROJECT_ID}");
		</script>

		<!-- Global site tag (gtag.js) - Google Analytics -->
		<script async src="https://www.googletagmanager.com/gtag/js?id=${PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}');
		</script>
	`}
	<!-- <meta name="keywords" content=""> -->
	<!-- Meta Pixel Code -->
	{@html `
		<script>
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s)}(window, document,'script',
			'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '${PUBLIC_FACEBOOK_PIXEL_ID}');
			fbq('track', 'PageView');
		</script>
		<noscript><img height="1" width="1" style="display:none"
		src="https://www.facebook.com/tr?id=${PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
		/></noscript>
	`}
	<!-- End Meta Pixel Code -->
	<meta name="description" content="ワタシモは、女性視点での働きやすさがわかる職場のクチコミ投稿プラットフォームです。その企業で働く女性社員のリアルな声を聞いてみたいというニーズにお応えします。">
	<meta property="og:description" content="ワタシモは、女性視点での働きやすさがわかる職場のクチコミ投稿プラットフォームです。その企業で働く女性社員のリアルな声を聞いてみたいというニーズにお応えします。">
	<meta property="og:locale" content="ja_JP">
	<meta property="og:type" content="website">
	<meta property="og:site_name" content="ワタシモ">
	<meta property="og:url" content={og_url}>
	<meta property="og:image" content="https://prod-assets.watashimo.app/ogp_1.png">
	<meta name="twitter:card" content="summary"/>
	<meta name="twitter:site" content="@watashimo_corp">
</svelte:head>

<Header on:logout={() => goto("/")} />

<main class="flex flex-col items-center {is_index && "main--index"}">
	<slot />
</main>

<Footer />

<style>
	main {
		@apply flex-1;
		max-width: 800px;
		width: 100%;
		margin: 0 auto;
	}

	main.main--index {
		max-width: unset;
	}

	:global(main > *) {
		width: 100%;
	}

	/* main {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 1rem;
		width: 100%;
		max-width: 1024px;
		margin: 0 auto;
		box-sizing: border-box;
	}

	footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px;
	}
	*/
</style>
