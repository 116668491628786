<script lang="ts">
	import { onMount, createEventDispatcher } from "svelte";

  import logo from "$lib/assets/logo_name_green.svg"
  import { isAuthenticated } from "$lib/auth/store";
  import auth from "$lib/auth";

	import type { Auth0Client } from "@auth0/auth0-spa-js";

  const dispatch = createEventDispatcher();
  let auth0Client: Auth0Client;

	onMount(async () => {
		auth0Client = await auth.createClient();
    try {
      await auth0Client.getTokenSilently();
      $isAuthenticated = true;
    } catch (error) {
      if (error.error !== 'missing_refresh_token' && error.error !== 'login_required') {
        throw error;
      }
    }
	})

  async function logout() {
    $isAuthenticated = false;
    auth.logout(auth0Client);
    dispatch("logout");
  }
</script>

<!-- pc は bg-primary-main にする? -->
<header class="h-12 flex items-center border-b-primary-light border-b-[1px] bg-white drop-shadow-sm">
  <p class="ml-4 text-2xl font-semibold text-primary-main mx-auto">
    <a href="/"><img src={logo} alt="ワタシモ" id="logo" /></a>
  </p>
  <div class="ml-auto flex-1 flex px-2">
    {#if $isAuthenticated}
      <p class="ml-auto text-xs text-primary-main cursor-pointer"><button on:click={logout}>ログアウト</button></p>
    {:else}
    <p class="ml-auto mr-4 text-xs text-primary-main"><a href="/auth/register">会員登録</a></p>
    <div class="w-1"/>
    <p class="text-xs text-primary-main"><a href="/auth/login">ログイン</a></p>
    {/if}
  </div>
</header>

<style>
img#logo {
  height: 20px;
}
</style>
